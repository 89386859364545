import React from 'react'
import Layout from "../components/layout"
import { Container } from "react-bootstrap"
import { Link, graphql, useStaticQuery } from "gatsby"

const Sitemap = () => {
  const siteMapData = useStaticQuery(graphql`
  query {
    allStrapiBurgerMenu(sort: { fields: sort, order: ASC }) {
      edges {
          node {
            title
            link {
              slug
            }
            custom_link
            add_new {
              title
              link {
                slug
              }
              add_sub_new {
                link {
                  slug
                  strapi_parent {
                      slug
                    }
                }
                label
              
              }
            }
          }
        }
    }

    allSitemapjson {
      edges {
        node {
          sitemap {
            label
            value
          }
        }
      }
    }

    
  }
  `)

  const menuData=siteMapData?.allStrapiBurgerMenu?.edges;
  const propertyData=siteMapData?.allSitemapjson?.edges[0]?.node?.sitemap;
  const filterSaleProperty=propertyData?.filter(item=>item.value?.includes("for-sale"));
  const saleVilla=filterSaleProperty?.filter(item=>item?.value?.includes("type-villa"))
  const saleAppartment=filterSaleProperty?.filter(item=>item?.value?.includes("type-apartment"))

  const filterRentProperties=propertyData?.filter(item=>item.value?.includes("to-rent"));
  const rentVilla =filterRentProperties?.filter(item=>item?.value?.includes("type-villa"))
  const rentAppartment=filterRentProperties?.filter(item=>item?.value?.includes("type-apartment"))
  const staticData=[
    {label:"Terms and Conditions", value:"/terms-and-conditions/"},
    {label:"Privacy Policy", value:"/privacy-policy/"},
    {label:"Cookie Policy", value:"/cookie-policy/"}
  ]

  const capitalizeFirst =(str)=>{
    if(!str) return;
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  return (
        <Layout dark>
          <div className="sitemap-container">
            <Container>
                <h2>Sitemap</h2>
                <div className="sitemap-list-module">
                <ul className="primary-list-sitemap">
                  {menuData?.map(data=>{
                    return(
                      <>
                        {data?.node?.title!=="More"&&
                        <li>
                          <Link to={data?.node?.custom_link}>
                            {data?.node?.title}
                          </Link>
                        </li>
                      }
                    </>
                    )
                  })}
                </ul>
                <div>
                {menuData?.map(data=>{
                    return(
                      <>
                        {data?.node?.title==="More"&&
                        <ul className="secondary-list-sitemap">
                          {data?.node?.add_new?.map(item=>{
                            return(
                              <>
                                <li className="parent-link-sitemap">
                                  <Link to={item?.link?.slug}>{item?.title}</Link>
                                  <ul>
                                  {item?.add_sub_new?.map(sub_item=>{
                                  const menuSlug=sub_item?.link?.strapi_parent?.slug?
                                  `/${sub_item?.link?.strapi_parent?.slug}/${sub_item?.link?.slug}/`: `/${sub_item?.link?.slug}/`
                                  return(
                                    <li className="child-link-sitemap">
                                      <Link to={menuSlug}>
                                        {sub_item?.label}
                                      </Link>
                                    </li>
                                  )
                                })}
                                  </ul>
                                </li>
                                
                              </>
                            )
                          })}
                        </ul>
                      }
                    </>
                    )
                  })}
                <ul className="static-list-module">
                  {staticData?.map(data=>{
                    return(
                      <li>
                        <Link to={data.value}>{data.label}</Link>
                      </li>
                    )
                  })}
                </ul>

                {/* <h2>Property Links</h2> */}
                {/* <ul className="property-list-sitemap">
                 
                 {propertyData?.slice(0,3000)?.map((item,index)=>{
                  return(
                    <li key={index}>
                      <Link to={item.value}>{capitalizeFirst(item.label)}</Link>
                    </li>
                  )
                 })} 
                 </ul> */}

                    <h2 className="p-type-sitemap">Villas for sale</h2>
                    <ul className="property-list-sitemap">
                    {saleVilla?.map((villa,index)=>{
                      return(
                        <li key={index}>
                          <Link to={villa.value}>{capitalizeFirst(villa.label)}</Link>
                        </li>
                      )
                    })}
                    </ul>

                    <h2 className="p-type-sitemap">Apartments for sale</h2>
                    <ul className="property-list-sitemap">
                    {saleAppartment?.map((apt,index)=>{
                      return(
                        <li key={index}>
                          <Link to={apt.value}>{capitalizeFirst(apt.label)}</Link>
                        </li>
                      )
                    })}
                    </ul>
                    <h2 className="p-type-sitemap">Villa to rent</h2>
                     <ul className="property-list-sitemap">
                        {rentVilla?.map((villa,index)=>{
                          return(
                            <li key={index}>
                              <Link to={villa.value}>{capitalizeFirst(villa.label)}</Link>
                            </li>
                          )
                        })}
                      </ul>
                    
                    <h2 className="p-type-sitemap">Apartments to rent</h2>
                    <ul className="property-list-sitemap">
                      {rentAppartment?.map((apt,index)=>{
                        return(
                          <li key={index}>
                            <Link to={apt.value}>{capitalizeFirst(apt.label)}</Link>
                          </li>
                        )
                      })}
                    </ul>

                </div>

                </div>
            </Container>
          </div>
        </Layout>
  )
}

export default Sitemap